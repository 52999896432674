<template>
  <v-card>
    <v-card-title> Notification Dashboard </v-card-title>
    <v-data-table
      :headers="headers"
      :items="getNotificationList"
      :items-per-page="5"
      :loading="loading"
      class="elevation-1"
    ></v-data-table>
  </v-card>
</template>

<script>
import { BehaviorSubject, defer } from "rxjs";
import { finalize, map, scan } from "rxjs/operators";

import { server } from "@/providers/http";

export default {
  subscriptions() {
    const loading = new BehaviorSubject(0).pipe(
      scan((a, b) => a + b),
      map(Boolean)
    );

    const getNotificationList = defer(() => {
      loading.next(1);
      return this.onList().pipe(
        finalize(() => {
          loading.next(-1);
        })
      );
    });

    return {
      loading,
      getNotificationList,
    };
  },
  data() {
    return {
      headers: [
        {
          text: "User",
          value: "identity",
        },
        { text: "Cause", value: "cause" },
        { text: "Injection Time", value: "adherenceReportedAt" },
        { text: "Notification requested Time", value: "requestedAt" },
      ],
      onList: () => server.get("notifications"),
    };
  },
};
</script>
